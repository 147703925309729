import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components';

import Breakpoints from "../../constants/breakpoints";

const getData = graphql`
  query {
    iterations: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Oracle_CCS/iterations" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    results: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Oracle_CCS/results" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

function withImageData(WrappedComponent) {
  return props => (
    <StaticQuery
      query={getData}
      render={data => <WrappedComponent {...props} imageData={data} />}
    />
  );
}

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
    flex-direction: column;
  }
`;

const IterationsImages = styled(ImageContainer)`
  > div {
    width: 32%;

    @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
      width: 100%;
      max-width: 100%;
    }

    &:not(:last-child) {
      @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
`;

const ResultsImages = styled(ImageContainer)`
  flex-wrap: wrap;
  justify-content: space-between;

   > div {
    width: 31%;
    margin-bottom: 3.5%;

    @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
      width: 100%;
      max-width: 100%;
    }

    &:not(:last-child) {
      @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
`;

export const Iterations = withImageData(props => (
  <IterationsImages lightText data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
    {props.imageData.iterations.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </IterationsImages>
));

export const Results = withImageData(props => (
  <ResultsImages lightText data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
    {props.imageData.results.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </ResultsImages>
));
