import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from 'gatsby';

import SEO from "../../components/seo";
import ProjectLayout from "../../components/layouts/projectLayout";
import Banner from "../../components/project/banner";
import ProjectSection from "../../components/project/projectSection";
import ProjectFooter from "../../components/project/projectFooter";
import {ProjectSectionTitle, ProjectSectionsubtitle, ProjectSectionDescription, ProjectSectionList, ProjectSectionListItem} from "../../components/project/projectTypography";
import {Iterations, Results} from "../../components/img/oracleCCSImages";


import Colors from "../../constants/colors";
import Fonts from "../../constants/fonts";
import Breakpoints from "../../constants/breakpoints";

// Images
import BannerImage from "../../images/project_banners/Project_banner-Oracle_CCS.png";
import IpadMockup from "../../images/projects/Oracle_CCS/mockup_on_tablet.jpg";
import PlusIcon from "../../images/icons/icon-plus-orange.svg";

import VisionPersonalProductivityImage from "../../images/projects/Oracle_CCS/vision/icon-Personal_Productivity.svg";
import VisionConnectedImage from "../../images/projects/Oracle_CCS/vision/icon-Connected.svg";
import VisionInsightsImage from "../../images/projects/Oracle_CCS/vision/icon-Insights.svg";

const getProject = graphql`
  query {
    project:allContentfulProject(filter: {title:{eq: "Oracle CCS"}}) {
      edges {
        node {
          title
          description {
            description
          }
          slug
          order
          year
          role
          contentful_id
        }
      }
    }
  }
`;

const VisionList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, minmax(10rem, 27rem));
  grid-gap: 2.5rem;
  justify-content: center;

  list-style: none;
  margin: 0;


  @media only screen and (max-width: ${Breakpoints.Width.laptop_medium}) {
    grid-gap: 1.5rem;
  }

  @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
    grid-gap: 1.2rem;
  }

  @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
  }
  
  li {
    display: block;
    background-color: ${Colors.Basic.white};
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    padding: 31px 24px 27px 160px;
    position: relative;

    @media only screen and (max-width: ${Breakpoints.Width.laptop_medium}) {
      padding: 31px 24px 27px 124px;
    }

    @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
      padding: 160px 24px 24px 24px;
    }

    @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
      padding: 40px 24px 40px 160px;
    }

    @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
      padding: 26px 24px 24px 140px;
    }

    @media only screen and (max-width: ${Breakpoints.Width.mobile_medium}) {
      padding: 160px 24px 24px 24px;
    }

    h4 {
      font-family: ${Fonts.Font_family.title_condensed};
      font-size: 1.25rem;
      line-height: 24px;
      margin-bottom: 6px;
    }

    p {
      font-size: 0.875rem;
    }

    &.vision-image {
      background-position: 24px 50%;
      background-size: 112px 112px;
      background-repeat: no-repeat;

      @media only screen and (max-width: ${Breakpoints.Width.laptop_medium}) {
        background-position: 24px 24px;
        background-size: 80px 80px;
      }

      @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
        background-position: 50% 24px;
        background-size: 112px 112px;
      }

      @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
        background-position: 24px 50%;
      }
      
      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        background-size: 96px 96px;
      }

      @media only screen and (max-width: ${Breakpoints.Width.mobile_medium}) {
        background-position: 50% 24px;
        background-size: 112px 112px;
      }
    }

    &.vision-image-personal_productivity {
      background-image: url(${VisionPersonalProductivityImage});
    }

    &.vision-image-connected {
      background-image: url(${VisionConnectedImage});
    }

    &.vision-image-insights {
      background-image: url(${VisionInsightsImage});
    }
  }
`;

const ConceptsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;

  list-style: none;
  margin: 0;

  @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
    grid-template-columns: 1fr;
  }
  
  > li {
    display: block;
    background-color: ${Colors.Basic.gray_98};
    padding: 24px;
    margin: 0;
    position: relative;

    &:nth-child(2) {
      background-color: ${Colors.Basic.gray_95};

      h4 {
        &::after {
          background-color: ${Colors.Underline.pink};
        }
      }
    }

    &:nth-child(3) {
      h4 {
        &::after {
          background-color: ${Colors.Underline.green};
        }
      }
    }

    h4 {
      font-size: 1.125rem;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 36px;
      position: relative;
      color: ${Colors.Text.emphasized};

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: -16px;
        background-color: ${Colors.Underline.blue};
      }
    }

    p {
      font-size: 0.875rem;
    }
    ul {
      margin: 0;
      list-style: none;

      li {
        color: ${Colors.Text.default};
        font-size: 0.875rem;
        line-height: 1.5;
        padding: 0 0 0 16px;
        background-image: url(${PlusIcon});
        background-position: 0 6px;
        background-repeat: no-repeat;
  
        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
      }
    }
  }
`;

const IpadMockupImage = styled.div`
  background-image: url(${IpadMockup});
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 400px;
`;

const OracleCCS = () => {
  const data = useStaticQuery(getProject);
  const project = data.project.edges[0].node;
  const sections = [
    {
      "name": "Vision",
      "to": "vision"
    },
    {
      "name": "Concepts",
      "to": "concepts"
    },
    {
      "name": "Iterations",
      "to": "iterations"
    },
    {
      "name": "Refinement",
      "to": "refinement"
    }
  ]

  return (  
    <ProjectLayout projectTitle={project.title} sections={sections}>
      <SEO title="Project - Koopio" />
      <Banner
        bgColor="#F2FAF7"
        bgImage={BannerImage}
        bgSize="auto 96%"
        bgPosY="0"
        title={project.title}
        description={project.description.description}
        year={project.year}
        role={project.role}
      />

      <ProjectSection bgColor={Colors.Basic.gray_98} description="false" name={sections[0].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[0].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Future of work in the modern enterprise
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description section-content__description--list">
            <ProjectSectionDescription>
              Reimagine Oracle Content and Document services with new ways to manage digital assets and to connect to people with cloud technology.  We summarized three areas to focus on.
            </ProjectSectionDescription>
            <ProjectSectionList>
              <ProjectSectionListItem>Personal Productivity</ProjectSectionListItem>
              <ProjectSectionListItem>Connected</ProjectSectionListItem>
              <ProjectSectionListItem>Insigts</ProjectSectionListItem>
            </ProjectSectionList>
          </div>
        </div>
        <VisionList>
          <li className="vision-image vision-image-personal_productivity" data-sal="slide-up" data-sal-delay="300" data-sal-duration="1200">
            <h4>Personal Productivity</h4>
            <p>Embrace mobility and digital assistants paradigms to improve personal productivity.</p>
          </li>
          <li className=" vision-image vision-image-connected" data-sal="slide-up" data-sal-delay="450" data-sal-duration="1200">
            <h4>Connected</h4>
            <p>Connect people and content to business applications and provide intelligent prioritization.</p>
          </li>
          <li className=" vision-image vision-image-insights" data-sal="slide-up" data-sal-delay="600" data-sal-duration="1200">
            <h4>Insights</h4>
            <p>Understand and use valuable information to improve engagement and interactions.</p>
          </li>
        </VisionList>
      </ProjectSection>

      <ProjectSection noDescription name={sections[1].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[1].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Dig into the focus area
            </ProjectSectionsubtitle>
          </div>
        </div>
        <ConceptsList>
          <li className="concepts-image concepts-image-personal_productivity" data-sal="slide-up" data-sal-delay="300" data-sal-duration="1200">
            <h4>Personal Productivity</h4>
            <ul>
              <li><strong>Focus</strong> – calendar integration, mobile notifications</li>
              <li><strong>Mobile integrations</strong> – mobile first, voice search, voice message etc.</li>
              <li><strong>Boards</strong> – Personalized curated content</li>
              <li><strong>Automate repetitive process</strong></li>
            </ul>
          </li>
          <li className=" concepts-image concepts-image-connected" data-sal="slide-up" data-sal-delay="450" data-sal-duration="1200">
            <h4>Connected</h4>
            <ul>
              <li><strong>Boards</strong> – Share curated content with the extended enterprise</li>
              <li><strong>Integrated with business applications</strong></li>
              <li><strong>3rd party Webhook integrations</strong></li>
            </ul>
          </li>
          <li className=" concepts-image concepts-image-insights" data-sal="slide-up" data-sal-delay="600" data-sal-duration="1200">
            <h4>Insights</h4>
            <ul>
              <li><strong>Content analytics at micro level</strong> – e.g. page/slide time spent, drop off points etc.</li>
              <li><strong>Content & people recommendations</strong></li>
              <li><strong>Easy search and filter facilitating faceted navigation</strong></li>
            </ul>
          </li>
        </ConceptsList>
      </ProjectSection>

      <ProjectSection bgColor="#3B4045" name={sections[2].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle color={Colors.Basic.white}>{sections[2].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle color={Colors.Basic.white}>
              Rapidly sketch, adjust, prototype, and test
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description">
            <ProjectSectionDescription color={Colors.Basic.white}>
              We quickly explored several ideas about how to present the information. After experimenting some layouts, we decided to use waterfall style interface combined with columns.
            </ProjectSectionDescription>
          </div>
        </div>
        <Iterations></Iterations>
      </ProjectSection>

      <IpadMockupImage data-sal="fade" data-sal-delay="800" data-sal-duration="800"></IpadMockupImage>

      
      <ProjectSection bgColor="#EBEBEB" noDescription name={sections[3].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[3].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Polish the details and evolve
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description">
            <ProjectSectionDescription>
              After designing the basic task flows, we continued to refine the details and develop new features and mobile phone app.
            </ProjectSectionDescription>
          </div>
        </div>
        <Results></Results>
      </ProjectSection>

      <ProjectFooter currentProjectOrder="5">
      </ProjectFooter>
    </ProjectLayout>
  )
};

export default OracleCCS;
